export interface IFilterItem {
    _key: string;
    name: string;
    month: number;
    year: number;
    count: number;
    children: {
        _key: string;
        name: string;
        count: number;
    }[];
}

export enum FilterTypesInResponse {
    TRAINING_TYPE = 'CourseType',
    CITY = 'City',
    CATEGORY = 'Category',
    MONTH = 'Month',
    LECTURER = 'Person',
    CONFERENCE_TYPE1 = 'ConferenceType1',
    CONFERENCE_TYPE2 = 'ConferenceType2',
}

export enum FilterTypesInUrl {
    TRAINING_TYPE = 'skolenie_typ',
    CITY = 'mesto',
    CATEGORY = 'kategoria',
    LECTURER = 'lektor',
    MONTH = 'mesiac',
    CONFERENCE_TYPE = 'konferencia_typ',
    ARCHIVED = 'zrealizovane',
    UPCOMING = 'aktualne',
    PAGE = 'strana',
}

export enum CourseTypes {
    UPCOMING = 'aktualne',
    ONLINE = 'online',
    ATTENDANCE = 'prezencne',
    EXPERIENCE = 'zazitkove',
    ARCHIVED = 'zaznamy',
}

export const MonthLabels = {
    1: 'január',
    2: 'február',
    3: 'marec',
    4: 'apríl',
    5: 'máj',
    6: 'jún',
    7: 'júl',
    8: 'august',
    9: 'september',
    10: 'október',
    11: 'november',
    12: 'december',
};

export enum ConferenceTypes {
    ARCHIVED = 'archived',
    UPCOMING = 'upcoming',
    DNATEACHER = 4,
    CS = 3,
    DNA = 2,
    EPI = 1,
    PP = 0,
}
