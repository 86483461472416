import Link from 'next/link';
import IconTemplate from '@components/Icon';
import { publicAssets } from '@utils/url.utils';
import { rem } from 'polished';
import styled from 'styled-components';
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../../constants';
import { breakpoints } from '../../styles/theme';

export const Desktop = styled.div`
    padding: 0 ${rem(15)} 0 ${rem(15)};
    height: ${rem(MOBILE_HEADER_HEIGHT)};
    position: fixed;
    z-index: 999;
    width: 100vw;
    top: 0;
    background: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    display: flex;
    align-items: center;

    @media (min-width: ${breakpoints.sm}px) {
        height: ${rem(DESKTOP_HEADER_HEIGHT)};
        padding: ${rem(20)};
        align-items: unset;
        justify-content: center;
    }
`;

export const Row = styled.div<{ $hideOnTablet?: boolean }>`
    display: ${(props) => (props.$hideOnTablet ? 'none' : 'flex')};
    flex-direction: row;
    justify-content: space-between;

    @media (min-width: ${breakpoints.sm}px) {
        display: flex;
        margin-top: ${rem(-15)};
    }
`;

export const Group = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${rem(15)};

    @media (min-width: ${breakpoints.sm}px) {
        gap: ${rem(20)};
    }
`;

export const MaxWidthWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @media (min-width: ${breakpoints.lg}px) {
        max-width: ${rem(1180)};
    }
`;

export const ContactLink = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.fontSecondary};
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fontSizes.textSmall};

    svg {
        width: ${rem(18)};
        height: ${rem(18)};
        margin-bottom: -${rem(5)};
        margin-right: ${rem(5)};
    }
`;

export const ContactLinkLabel = styled.span`
    display: none;

    @media (min-width: ${breakpoints.lg}px) {
        display: block;
    }
`;

export const SearchSection = styled.div`
    background: ${({ theme }) => theme.colors.white};
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: ${rem(10)} ${rem(15)} 0 ${rem(15)};
    z-index: 9999;

    svg {
        position: absolute;
    }

    button {
        position: absolute;
        right: ${rem(17)};
        top: ${rem(12)};
    }

    @media (min-width: ${breakpoints.sm}px) {
        position: relative;
        padding: 0;
        width: auto;
        height: auto;

        button {
            right: ${rem(2)};
            top: ${rem(2)};
        }
    }
`;

export const SearchCloseButton = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    position: absolute;
    bottom: ${rem(4)};
    padding: 0;
    background: none;
    border: none;
    left: 40%;

    @media (min-width: ${breakpoints.sm}px) {
        display: none;
    }
`;

export const SearchInput = styled.input`
    height: ${rem(40)};
    border-radius: ${rem(6)};
    padding: ${rem(10)} ${rem(10)} ${rem(10)} ${rem(35)};
    width: 100%;
    font-size: ${rem(14)};
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: url(${publicAssets('/search.svg')}) no-repeat;
    background-position: left ${rem(10)} bottom 50%;
    background-size: ${rem(20)};

    @media (min-width: ${breakpoints.sm}px) {
        width: ${rem(320)};
    }

    @media (min-width: ${breakpoints.md}px) {
        width: ${rem(380)};
    }
`;

export const Account = styled.div`
    position: relative;
`;

export const Notifications = styled.div`
    position: relative;
`;

export const NavigationNotifications = styled.div`
    background: ${({ theme }) => theme.colors.white};
    position: absolute;
    width: ${rem(275)};
    right: ${rem(0)};
    border-radius: ${rem(4)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: ${rem(40)};
    z-index: 99999;
    border: 1px solid ${({ theme }) => theme.colors.border};
    max-height: ${rem(400)};
    overflow-y: scroll;

    @media (min-width: ${breakpoints.sm}px) {
        width: ${rem(340)};
        right: ${rem(10)};
    }
`;

export const NavigationEmptyMessage = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding: ${rem(40)} ${rem(15)};
    font-family: ${({ theme }) => theme.fonts.fontSecondary};
    text-align: center;
    justify-content: center;
    line-height: ${rem(25)};
    color: ${({ theme }) => theme.colors.grey};
`;

export const NavigationNotification = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: ${rem(20)};
    padding: ${rem(20)} ${rem(30)} ${rem(20)} ${rem(20)};
    position: relative;
    cursor: pointer;

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    }

    &:hover,
    &:focus-visible {
        background: ${({ theme }) => theme.colors.darkLight};
    }
`;

export const NavigationNotificationIcon = styled(IconTemplate)`
    svg {
        width: ${rem(30)};
        height: ${rem(30)};
    }
`;

export const NavigationNotificationDetail = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(5)};
`;

export const NavigationNotificationDetailTitle = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
`;

export const NavigationNotificationDetailDescription = styled.p`
    margin: ${rem(2)} 0 ${rem(4)} 0;
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    color: ${({ theme }) => theme.colors.dark};
`;

export const NavigationNotificationCloseButton = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    color: ${({ theme }) => theme.colors.grey};
    cursor: pointer;
    opacity: 0.8;
    position: absolute;
    top: ${rem(8)};
    right: ${rem(14)};
`;

export const NavigationNotificationDetailDate = styled.span<{ isPrimary: boolean }>`
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    color: ${(props) => (props.isPrimary ? props.theme.colors.primary : props.theme.colors.grey)};
`;

export const NavigationNotificationDetailDateUnread = styled.div`
    display: inline-block;
    background: ${({ theme }) => theme.colors.primary};
    width: ${rem(10)};
    height: ${rem(10)};
    border-radius: 50%;
    margin-left: ${rem(10)};
`;

export const NavigationNotificationShowMoreLink = styled.button`
    background: none;
    border: none;
    margin: 0 auto;
    padding: ${rem(10)} 0;
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
`;

export const NavigationAccount = styled.div`
    background: ${({ theme }) => theme.colors.white};
    position: absolute;
    width: ${rem(300)};
    right: ${rem(10)};
    border-radius: ${rem(4)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 99999;
    border: 1px solid ${({ theme }) => theme.colors.border};
`;

export const NavigationAccountHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${rem(10)};
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

export const NavigationAccountLabel = styled.span`
    padding: 0 0 ${rem(10)} ${rem(25)};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-size: ${({ theme }) => theme.fontSizes.h6};
    margin-top: ${rem(15)};
`;

export const NavigationAccountMail = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.h6};
    padding: 0 0 ${rem(15)} ${rem(25)};
`;

export const NavigationAccountCloseButton = styled.span`
    text-align: right;
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    padding: ${rem(10)} ${rem(10)} 0 0;
    cursor: pointer;
`;

export const NavigationAccountLink = styled(Link)`
    margin-left: ${rem(25)};
    background: none;
    padding: ${rem(4)} 0;
    cursor: pointer;
    border: none;
    text-decoration: none;

    &:hover,
    &:focus-visible {
        color: ${({ theme }) => theme.colors.primary};
    }
`;

export const Logo = styled.img`
    width: ${rem(145)};
    height: ${rem(65)};
    cursor: pointer;
    margin-left: ${rem(-10)};

    @media (min-width: ${breakpoints.sm}px) {
        width: ${rem(160)};
        height: ${rem(70)};
    }
`;

export const NavigationDesktop = styled.div`
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    display: none;
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fonts.fontSecondary};

    @media (min-width: ${breakpoints.sm}px) {
        display: block;
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        white-space: nowrap;
    }

    @media (min-width: ${breakpoints.sm}px) {
        display: block;
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
    }
`;

export const NavigationMobile = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.fonts.fontSecondary};
    color: ${({ theme }) => theme.colors.grey};
    position: absolute;
    left: 0;
    top: 0;
    width: ${rem(300)};
    height: 100vh;
    background: ${({ theme }) => theme.colors.white};
    padding: ${rem(20)};
    overflow-y: scroll;

    @media (min-width: ${breakpoints.lg}px) {
        display: none;
    }

    &.isOpen {
        transform: translateX(0);
        opacity: 1;
        transition: transform 0.5s, opacity 1s;
    }

    &.isClose {
        transform: translateX(-300px);
        opacity: 0;
        transition: transform 1s, opacity 1s;
    }
`;

export const NavigationItem = styled(Link)<{ $marginTop?: number }>`
    margin: ${(props) => (props.$marginTop ? `${rem(props.$marginTop)} 0 ${rem(15)} 0` : `${rem(15)} 0`)};
    cursor: pointer;
    color: ${({ theme }) => theme.colors.black};
    text-decoration: none;

    &:hover,
    &:focus-visible {
        color: ${({ theme }) => theme.colors.primary};
    }

    svg {
        width: ${rem(20)};
        height: ${rem(20)};
        margin-bottom: -${rem(5)};
        margin-right: ${rem(10)};
    }

    @media (min-width: ${breakpoints.sm}px) {
        margin: ${rem(8)} ${rem(25)} ${rem(8)} 0;

        &:hover,
        &:focus-visible {
            border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
            padding-bottom: ${rem(20)};
        }

        &.isActive {
            border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
            padding-bottom: ${rem(20)};
        }
    }

    &.isActive {
        color: ${({ theme }) => theme.colors.primary};
    }
`;

export const NavigationCloseButton = styled.span`
    position: absolute;
    top: ${rem(10)};
    right: ${rem(10)};
    font-size: ${({ theme }) => theme.fontSizes.h5};
    cursor: pointer;
    color: ${({ theme }) => theme.colors.black};
`;

export const MySWButton = styled.div`
    display: none;

    @media (min-width: ${breakpoints.sm}px) {
        display: block;
    }
`;

export const LoginButton = styled.div`
    display: none;

    @media (min-width: ${breakpoints.sm}px) {
        display: block;
    }
`;

export const MenuIcon = styled(IconTemplate)`
    display: block;
    cursor: pointer;
    order: 5;

    svg {
        width: ${rem(25)};
        height: ${rem(25)};
    }

    @media (min-width: ${breakpoints.sm}px) {
        display: none;
        order: unset;

        svg {
            width: ${rem(30)};
            height: ${rem(30)};
        }
    }
`;

export const SearchIcon = styled(IconTemplate)`
    display: block;
    cursor: pointer;

    svg {
        width: ${rem(25)};
        height: ${rem(25)};
    }

    @media (min-width: ${breakpoints.sm}px) {
        display: none;
    }
`;

export const NotificationIcon = styled(IconTemplate)`
    cursor: pointer;

    svg {
        width: ${rem(25)};
        height: ${rem(25)};
        margin-top: ${rem(-2)};
    }

    @media (min-width: ${breakpoints.sm}px) {
        svg {
            width: ${rem(30)};
            height: ${rem(30)};
        }
    }
`;

export const NotificationUnread = styled.div`
    background: ${({ theme }) => theme.colors.primary};
    width: ${rem(10)};
    height: ${rem(10)};
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
`;

export const AccountIcon = styled(IconTemplate)`
    cursor: pointer;

    svg {
        width: ${rem(30)};
        height: ${rem(30)};

        path {
            fill: ${({ theme }) => theme.colors.primary};
        }
    }

    @media (min-width: ${breakpoints.sm}px) {
        svg {
            width: ${rem(40)};
            height: ${rem(40)};
        }
    }
`;

export const CardSection = styled.div`
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

export const CardCount = styled.span`
    position: absolute;
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    right: -${rem(8)};
    top: -${rem(8)};
    border-radius: 50%;
    width: ${rem(20)};
    height: ${rem(20)};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
`;

export const CardIcon = styled(IconTemplate)`
    svg {
        width: ${rem(22)};
        height: ${rem(22)};
    }

    @media (min-width: ${breakpoints.sm}px) {
        svg {
            width: ${rem(25)};
            height: ${rem(25)};
        }
    }
`;
